<template>
  <div>
    <div class="bookingSpaceTitle" v-if="pageType == 1 || pageType == 2">
      <div class="title" v-if="pageType == 1">
        您的订舱委托已生成，客服正在核对资料！
      </div>
      <div class="title" v-if="pageType == 2">
        您的订舱委托已更新，客服正在核对资料！
      </div>
      <div class="hint">24小时内将有客服人员与您联系，请保持电话畅通</div>
      <div class="btns">
        <el-button
          type="primary"
          size="medium"
          @click="handleHome"
          v-if="pageType == 1"
          >返回首页</el-button
        >
        <el-button
          type="primary"
          size="medium"
          @click="handleBackEntrust"
          v-if="pageType == 2"
          >返回我的委托</el-button
        >
        <el-button
          type="danger"
          size="medium"
          class="btn2"
          @click="handleBookingSpaceDetails"
          >查看订舱详情</el-button
        >
      </div>
    </div>
    <div class="bookingSpaceTitle" v-if="pageType == 3">
      <div class="title">
        客户订单已生成，信息正在核对！
      </div>
      <div class="btns">
        <el-button
          type="primary"
          size="medium"
          @click="handleShippingOrder"
          >返回海运订单列表</el-button
        >
        <el-button
          type="danger"
          size="medium"
          class="btn2"
          @click="handleBookingSpaceDetails2"
          >查看订单详情</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bookingSpaceTitle",
  data() {
    return {
      // 1是从订舱那边跳过来，订舱委托成功
      // 2是从控制台那边过来，重新委托成功
      // 3是从控制台那边过来，销售帮客户订舱
      pageType: 1,
    };
  },
  mounted() {
    console.log(this.$route.query.pageType, "this.$route.query.pageType");
    if (this.$route.query.pageType) {
      this.pageType = this.$route.query.pageType;
    }
    if (this.$route.query.isFcl) {
    }
  },
  methods: {
    // 返回首页
    handleHome() {
      this.$router.push("/");
    },
    handleShippingOrder() {
      this.$router.push("/console/shippingOrder");
    },
    handleBookingSpaceDetails2() {
      this.$router.push({
        path: '/console/clients/booking',
        query: {
          id: this.$route.query.id,
          status: this.$route.query.status
        }
      });
    },
    // 去详情
    handleBookingSpaceDetails() {
      if (this.$route.query.isFcl) {
         let id = this.$route.query.id
          this.$router.push({
            path: "/console/myEntrust/fclOrderDetail",
            query: { orderId: id },
          });
      } else {
        let id = this.$route.query.id;
        let type = this.$route.query.type;
        let status = this.$route.query.status;
        this.$router.push({
          path: "/console/myEntrust/details",
          query: { id, type, status },
        });
      }
    },
    // 去我的委托
    handleBackEntrust() {
      this.$router.push("/console/myEntrust/list");
    },
  },
};
</script>

<style scoped lang="less">
.bookingSpaceTitle {
  min-height: 72vh;
}
.title {
  margin-top: 100px;
  font-size: 26px;
  font-weight: 800;
  color: #000000;
  text-align: center;
}
.hint {
  margin-top: 40px;
  font-size: 18px;
  font-weight: 800;
  color: #000000;
  text-align: center;
}
.btns {
  text-align: center;
  margin-top: 60px;
  .btn2 {
    margin-left: 30px;
  }
  ::v-deep .el-button--medium{
    width: 200px;
  }
}
</style>